.orders {
  display: flex;
  justify-content: center;
  color: #555;
}

.orders .container {
  width: 1400px;
  padding: 50px 0px;
}

.orders .container .title {
  display: flex;
  justify-content: space-between;
}

.orders .container .title button {
  background-color: #1dbf73;
  color: white;
  font-weight: 500;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.orders .container table {
  width: 100%;
}

.orders .container table tr {
  height: 50px;
}

.orders .container table tr th {
  text-align: left;
}

.orders .container table tr td sup {
  font-size: 12px;
}

.orders .container table tr td .image {
  width: 50px;
  height: 25px;
  object-fit: cover;
}

.orders .container table tr td .message {
  width: 25px;
  cursor: pointer;
}

.orders .container table tr:nth-child(even) {
  background-color: #1dbf730f;
}
