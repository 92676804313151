.gig {
  display: flex;
  justify-content: center;
}

.gig .container {
  width: 1400px;
  padding: 30px 0px;
  display: flex;
  gap: 50px;
}

.gig .container .left {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gig .container .left .breadcrumbs {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 13px;
  color: #555;
}

.gig .container .left h1 {
}

.gig .container .left .user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gig .container .left .user .pp {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.gig .container .left .user span {
  font-size: 14px;
  font-weight: 500;
}

.gig .container .left .user .stars {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gig .container .left .user .stars img {
  height: 14px;
  width: 14px;
}

.gig .container .left .user .stars span {
  font-size: 14px;
  font-weight: bold;
  color: #ffc108;
}

.gig .container .left .slider {
  background-color: #F5F5F5;
}

.gig .container .left .slider img {
  max-height: 500px;
  object-fit: contain;
}

.gig .container .left h2 {
  font-weight: 400;
}

.gig .container .left p {
  font-weight: 300;
  line-height: 25px;
  color: #555;
}

.gig .container .left .seller {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gig .container .left .seller .user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gig .container .left .seller .user img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.gig .container .left .seller .user .info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gig .container .left .seller .user .info .stars {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gig .container .left .seller .user .info .stars img {
  height: 14px;
  width: 14px;
}

.gig .container .left .seller .user .info .stars span {
  font-size: 14px;
  font-weight: bold;
  color: #ffc108;
}

.gig .container .left .seller .user .info button {
  background-color: white;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 10px;
}

.gig .container .left .seller .box {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.gig .container .left .seller .box .items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gig .container .left .seller .box .items .item {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.gig .container .left .seller .box .items .item .title {
  font-weight: 300;
}

.gig .container .left .seller .box hr {
  height: 0;
  border: 0.5px solid lightgray;
  margin-bottom: 20px;
}

.gig .container .right {
  flex: 1;
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: max-content;
  max-height: 500px;
  position: sticky;
  top: 150px;
}

.gig .container .right .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gig .container .right .price h2 {
  font-weight: 300;
}

.gig .container .right .price h3 {
  font-weight: 500;
}

.gig .container .right p {
  color: gray;
  margin: 10px 0px;
}

.gig .container .right .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.gig .container .right .details .item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gig .container .right .details .item img {
  width: 20px;
}

.gig .container .right .features .item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 300;
  color: gray;
  margin-bottom: 5px;
}

.gig .container .right .features .item img {
  width: 14px;
}

.gig .container .right button {
  background-color: #1dbf73;
  padding: 10px;
  color: white;
  font-weight: 500;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
