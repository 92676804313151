.add {
  display: flex;
  justify-content: center;
}

.add .container {
  width: 1400px;
  padding: 50px 0px;
}

.add .container h1 {
  width: max-content;
  margin-bottom: 30px;
  color: gray;
  font-weight: 300;
}

.add .container .sections {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.add .container .sections label {
  color: gray;
  font-size: 18px;
}

.add .container .sections .info,
.add .container .sections .details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.add .container .sections .info input,
.add .container .sections .info select,
.add .container .sections .info textarea,
.add .container .sections .details input,
.add .container .sections .details select,
.add .container .sections .details textarea {
  padding: 20px;
}

.add .container .sections .info .images,
.add .container .sections .details .images {
  display: flex;
  align-items: center;
  gap: 20px;
}

.add .container .sections .info .images .imagesInputs,
.add .container .sections .details .images .imagesInputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add .container .sections .info button,
.add .container .sections .details button {
  border: none;
  padding: 20px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  background-color: #1dbf73;
  cursor: pointer;
}

.add .container .sections .info .add input,
.add .container .sections .details .add input {
  width: 80%;
}

.add .container .sections .info .addedFeatures,
.add .container .sections .details .addedFeatures {
  display: flex;
  gap: 20px;
}

.add .container .sections .info .addedFeatures button,
.add .container .sections .details .addedFeatures button {
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  background: transparent;
  color: red;
  border: 1px solid red;
  display: flex;
  align-items: center;
  gap: 20px;
}
