.projectCard {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgb(225, 225, 225);
}

.projectCard img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.projectCard .info {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
}

.projectCard .info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.projectCard .info .texts h2 {
  font-size: 14px;
  font-weight: 500;
}

.projectCard .info .texts span {
  font-size: 14px;
  font-weight: 300;
}
