.trustedBy {
  background-color: #fafafa;
  height: 100px;
  display: flex;
  justify-content: center;
}

.trustedBy .container {
  max-width: 760px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: rgb(200, 200, 200);
  font-weight: 500;
}

.trustedBy .container img {
  height: 50px;
  object-fit: contain;
}
