.slide {
  width: 100%;
}

.container {
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slides {
  display: flex;
}

.card {
  flex: 0 0 auto;
  width: calc(100% / 5); 
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}


.slide .react-multi-carousel-item {
  margin-right: 0px;
  padding: 0px;
}

.slide .react-multi-carousel-list {
  padding: 0px;
}
