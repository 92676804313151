.featured {
  height: 600px;
  display: flex;
  justify-content: center;
  background-color: #013914;
  color: white;
}

.featured .container {
  width: 1400px;
  display: flex;
  align-items: center;
}

.featured .container .left {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.featured .container .left h1 {
  font-size: 50px;
}

.featured .container .left h1 span {
  font-style: italic;
  font-weight: 300;
}

.featured .container .left .search {
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.featured .container .left .search .searchInput {
  display: flex;
  align-items: center;
  gap: 10px;
}

.featured .container .left .search .searchInput img {
  width: 20px;
  height: 20px;
  margin: 10px;
}

.featured .container .left .search .searchInput input {
  border: none;
  outline: none;
}

.featured .container .left .search .searchInput input::placeholder {
  color: gray;
}

.featured .container .left .search button {
  width: 120px;
  height: 50px;
  border: none;
  background-color: #1dbf73;
  color: white;
  align-self: flex-end;
  cursor: pointer;
}

.featured .container .left .popular {
  display: flex;
  align-items: center;
  gap: 10px;
}

.featured .container .left .popular span {
  width: max-content;
}

.featured .container .left .popular button {
  width: max-content;
  color: white;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: transparent;
  font-size: 14px;
}

.featured .container .right {
  height: 100%;
}

.featured .container .right img {
  height: 100%;
  object-fit: contain;
}
