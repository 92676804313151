.myGigs {
  display: flex;
  justify-content: center;
  color: #555;
}

.myGigs .container {
  width: 1400px;
  padding: 50px 0px;
}

.myGigs .container .title {
  display: flex;
  justify-content: space-between;
}

.myGigs .container .title button {
  background-color: #1dbf73;
  color: white;
  font-weight: 500;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.myGigs .container table {
  width: 100%;
}

.myGigs .container table tr {
  height: 50px;
}

.myGigs .container table th {
  text-align: left;
}

.myGigs .container table td sup {
  font-size: 12px;
}

.myGigs .container table td .image {
  width: 50px;
  height: 25px;
  object-fit: cover;
}

.myGigs .container table td .delete {
  width: 20px;
  cursor: pointer;
}

.myGigs .container table tr:nth-child(even) {
  background-color: #1dbf730f;
}
