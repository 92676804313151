.footer {
  display: flex;
  justify-content: center;
  color: #666;
  margin: 50px 0px;
}

.footer img {
  width: 24px;
  height: 24px;
}

.footer .container {
  width: 1400px;
}

.footer .container .top {
  display: flex;
  justify-content: space-between;
}

.footer .container .top .item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .container .top .item h2 {
  font-size: 16px;
  color: #555;
}

.footer .container .top .item span {
  font-weight: 300;
}

.footer .container hr {
  margin: 50px 0px;
  height: 0;
  border: 1px solid #ebe9e9;
}

.footer .container .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .container .bottom .left {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer .container .bottom .left span {
  font-size: 13px;
  white-space: nowrap;
}

.footer .container .bottom .right {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer .container .bottom .right .social {
  display: flex;
  gap: 20px;
}

.footer .container .bottom .right .link {
  display: flex;
  align-items: center;
  gap: 10px;
}
