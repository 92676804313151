.catCard {
  width: 252px;
  height: 344px;
  color: white;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.catCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.catCard .desc {
  font-weight: 300;
  position: absolute;
  top: 15px;
  left: 15px;
}

.catCard .title {
  position: absolute;
  top: 40px;
  left: 15px;
  font-size: 24px;
  font-weight: 500;
}
