.login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login form {
  width: 360px;
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login form h1 {
  color: gray;
  margin-bottom: 20px;
}

.login form label {
  color: gray;
  font-size: 18px;
}

.login form input,
.login form textarea {
  padding: 20px;
  border: 1px solid rgb(216, 214, 214);
}

.login form button {
  border: none;
  padding: 20px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  background-color: #1dbf73;
  cursor: pointer;
}

.login form span {
  color: red;
  font-size: 12px;
}
