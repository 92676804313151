.gigCard {
  width: 324px;
  height: 400px;
  border: 1px solid rgb(228, 228, 228);
  margin-bottom: 40px;
}

.gigCard img {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.gigCard .info {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gigCard .info .user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gigCard .info .user img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
}

.gigCard .info p {
  color: #111;
}

.gigCard .info .star {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gigCard .info .star img {
  height: 14px;
  width: 14px;
}

.gigCard .info .star span {
  font-size: 14px;
  font-weight: bold;
  color: #ffc108;
}

.gigCard hr {
  height: 0;
  border: 0.5px solid rgb(228, 228, 228);
}

.gigCard .detail {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gigCard .detail img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  object-fit: cover;
}

.gigCard .detail .price span {
  color: #999;
  font-size: 12px;
}

.gigCard .detail .price h2 {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  text-align: end;
}

.gigCard .detail .price sup {
  font-size: 12px;
  font-weight: 300;
}
